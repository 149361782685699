import { WELD_INTRODUCTORY_CALL_URL } from "constants/external-urls/hubspot";
import { ReactNode } from "react";
import { useDebouncedCallback } from "use-debounce";

import { ConnectionBaseFragment, IntegrationAbility } from "@/apollo/types";
import confirm from "@/components/elements/Confirm";
import { ConnectorOption } from "@/features/connectors";
import { useConnectionsByIntegrationId } from "@/features/connectors/lib/useConnectionsByIntegrationId";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useMixpanel } from "@/monitoring/mixpanel";

import { ConnectorsContext } from "./ConnectorsProvider";
import {
  AliasConnectorDialog,
  shouldShowAliasConnectorDialog,
} from "./ui/AliasConnectorDialog";
import { BetaConnectorAccessRequestDialog } from "./ui/BetaConnectorAccessRequestDialog";
import { CommunityCustomConnectorDialog } from "./ui/CommunityCustomConnectorDialog";
import { SetupSampleDataDialog } from "./ui/SetupSampleDataDialog";
import { useConnectors } from "./useConnectors";

export function Connectors(props: {
  options: ConnectorOption[];
  abilityFilter?: IntegrationAbility;
  sortOption?: "alphabetical" | "popular";
  enableSelectExistingConnection?: boolean;
  onSelectIntegration: (option: ConnectorOption) => void;
  onSelectConnection?: (connection: ConnectionBaseFragment) => void;
  onOptionsFiltered?: (
    options: ConnectorOption[],
    filters: {
      abilityFilter: IntegrationAbility | null;
      searchText: string;
    },
  ) => void;
  onAbilityFilterChange?: (ability: IntegrationAbility | null) => void;
  children:
    | ReactNode
    | ((
        props: Pick<ReturnType<typeof useConnectors>, "options"> & {
          onSelectIntegration: (option: ConnectorOption) => void;
          onSelectConnection?: (connection: ConnectionBaseFragment) => void;
          filters: {
            searchText: boolean;
          };
        },
      ) => ReactNode);
}) {
  const connectors = useConnectors({
    options: props.options,
    abilityFilter: props.abilityFilter,
    sortOption: props.sortOption,
    onAbilityFilterChange: props.onAbilityFilterChange,
    onOptionsFiltered(filteredOptions, filters) {
      if (filters.searchText.trim() === "") {
        return;
      }
      trackDebounced("Connection Options Filtered", {
        resultCount: filteredOptions.length,
        abilityFilter: filters.abilityFilter,
        searchText: filters.searchText.trim().toLowerCase(),
      });
    },
  });

  const existingConnectionsByIntegrationId = useConnectionsByIntegrationId();

  const communityConnectorDisclosure = useDisclosure<ConnectorOption>();
  const betaConnectorDisclosure = useDisclosure<ConnectorOption>();
  const aliasConnectorDisclosure = useDisclosure<ConnectorOption>();
  const setupSampleDataDisclosure = useDisclosure<ConnectorOption>();

  const handleIntegrationSelected = async (option: ConnectorOption) => {
    if (option.integration.id === "email") {
      if (
        await confirm({
          message:
            "For an email connector that directly loads CSV and JSON files from your email into your data warehouse, please contact our sales team.",
          title: "Contact sales to setup",
          confirmButtonText: "Contact now",
        })
      ) {
        window.open(WELD_INTRODUCTORY_CALL_URL);
      }
    } else if (option.integration.id === "weld-sample-data") {
      setupSampleDataDisclosure.onOpen(option);
    } else if (option.isAccessRequestable) {
      betaConnectorDisclosure.onOpen(option);
    } else if (option.isCommunity) {
      communityConnectorDisclosure.onOpen(option);
    } else if (option.isAlias && shouldShowAliasConnectorDialog(option)) {
      aliasConnectorDisclosure.onOpen(option);
    } else {
      props.onSelectIntegration(option);
    }
  };

  const mixpanel = useMixpanel();
  const trackDebounced = useDebouncedCallback(mixpanel.track, 500);

  const ctx = {
    ...connectors,
    onSelectIntegration: handleIntegrationSelected,
    onSelectConnection: props.onSelectConnection,
    getExistingConnections: (integrationId: string) => {
      return existingConnectionsByIntegrationId.get(integrationId) ?? [];
    },
    enableSelectExistingConnection:
      props.enableSelectExistingConnection ?? false,
    filters: {
      searchText: connectors.searchText.trim().length > 0,
    },
  };

  return (
    <>
      <ConnectorsContext.Provider value={ctx}>
        {typeof props.children === "function"
          ? props.children(ctx)
          : props.children}
      </ConnectorsContext.Provider>
      {communityConnectorDisclosure.data && (
        <CommunityCustomConnectorDialog
          option={communityConnectorDisclosure.data}
          onConnect={(option) => {
            props.onSelectIntegration(option);
            communityConnectorDisclosure.onClose();
          }}
          isOpen={communityConnectorDisclosure.isOpen}
          onClose={communityConnectorDisclosure.onClose}
        />
      )}
      {betaConnectorDisclosure.data && (
        <BetaConnectorAccessRequestDialog
          option={betaConnectorDisclosure.data}
          isOpen={betaConnectorDisclosure.isOpen}
          onClose={betaConnectorDisclosure.onClose}
        />
      )}
      {aliasConnectorDisclosure.data && (
        <AliasConnectorDialog
          option={aliasConnectorDisclosure.data}
          existingConnections={existingConnectionsByIntegrationId.get(
            aliasConnectorDisclosure.data.integration.aliasMetadata?.id ?? "",
          )}
          isOpen={aliasConnectorDisclosure.isOpen}
          onClose={aliasConnectorDisclosure.onClose}
          onSelectIntegration={(option) => {
            props.onSelectIntegration(option);
            aliasConnectorDisclosure.onClose();
          }}
          onSelectConnection={(connection) => {
            props.onSelectConnection?.(connection);
          }}
        />
      )}
      {setupSampleDataDisclosure.data && (
        <SetupSampleDataDialog
          show
          onClose={setupSampleDataDisclosure.onClose}
          onSelectConnection={props.onSelectConnection}
        />
      )}
    </>
  );
}
